// @flow

import React, { useEffect } from "react";
import { EstimationTool, Meta } from "@components";
import { PageContent } from "@containers";
import { graphql } from "gatsby";
import { addLocale, useLocale, t } from "ttag";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { EVENT_MAP, type PageImpressionParams } from "../models/events";
import { TrackPageImpression } from "../components/Tracker/Tracker";
const EstimationToolTemplate = ({
    data: {
        c21Translation: { translations },
        contentfulNavigation: { navigationItems },
        allC21Agency: { nodes },
        footerItems,
        contentfulWebLandingPage: { pageContent },
    },
    pageContext: { locale, translationSlugs, country },
}: *) => {
    addLocale(locale, translations);
    useLocale(locale);
    const gtmDispatcher = useGTMDispatch();
    useEffect(() => {
        const paramsString = location.search;
        let searchParams = new URLSearchParams(paramsString);

        const params: PageImpressionParams = {
            agency: searchParams.get("agency")
                ? {
                      id: searchParams.get("agency") || "",
                      name: "",
                      city: "",
                      postalcode: "",
                      country: "",
                  }
                : null,
            agencypage: "false",
            language: locale,
            pagename: "Estimation tool",
            typetemplate: "estimation-tool",
        };
        TrackPageImpression(gtmDispatcher, EVENT_MAP.PageImpression, params);
    }, []);
    return (
        <PageContent
            navigationItems={navigationItems}
            footerItems={footerItems}
            translationSlugs={translationSlugs}
            locale={locale}
            country={country}
        >
            <Meta
                title={t`title.estimation-tool`}
                description={t`meta.description.estimation-tool`}
                translationSlugs={translationSlugs}
                locale={locale}
            />
            <EstimationTool
                agencyData={nodes}
                locale={locale}
                contentfulData={pageContent}
            />
        </PageContent>
    );
};

export default EstimationToolTemplate;

export const query = graphql`
    query ($locale: String!, $country: String!) {
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        allC21Agency(filter: { locale: { eq: $locale } }) {
            nodes {
                name
                api_id
                address {
                    city
                    postalCode
                }
            }
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "common.text.and"
                    "common.text.language"
                    "form.estimation-tool.*"
                    "estimation-tool.*"
                    "title.estimation-tool"
                    "meta.description.estimation-tool"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                ]
            )
        }
        contentfulWebLandingPage(
            contentful_id: { eq: "Vdrvzbo8KI10yI0kRDnJ4" }
            node_locale: { eq: $locale }
        ) {
            pageTitle
            pageContent {
                ... on ContentfulQuickLinkBlock {
                    id
                    type
                    title
                    hideStepsTitle
                    linkItems {
                        ... on ContentfulLink {
                            id
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                            linkTitle
                            linkSubtitle {
                                linkSubtitle
                            }
                            icon {
                                fixed(width: 100, height: 100) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                            }
                            image {
                                fixed(width: 217, height: 185) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                                description
                            }
                        }
                        ... on ContentfulCollection {
                            internalLabel
                            query
                            content {
                                contentTitle
                                squareImage {
                                    fixed(width: 217, height: 185) {
                                        width
                                        height
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                    description
                                }
                            }
                        }
                        ... on ContentfulWebLandingPage {
                            id
                            slug
                            pageContent {
                                ... on ContentfulBlog {
                                    id
                                    title
                                    heroImage {
                                        fluid(maxWidth: 568, quality: 80) {
                                            ...GatsbyContentfulFluid_withWebp
                                        }
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
                ... on ContentfulImageInformationBlock {
                    id
                    node_locale
                    type
                    link {
                        linkTitle
                        contentfulInternalLink {
                            ... on ContentfulWebLandingPage {
                                slug
                            }
                        }
                    }
                    content {
                        contentTitle
                        contentSubtitle
                        landscapeImage {
                            fluid(maxWidth: 1172) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                        squareImage {
                            fluid(maxWidth: 600) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                    }
                }
                ... on ContentfulBlog {
                    id
                    title
                    author {
                        name
                        link
                        image {
                            fixed(width: 100, height: 100) {
                                width
                                height
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                            }
                        }
                    }

                    content {
                        ... on ContentfulRichText {
                            childContentfulRichTextTextRichTextNode {
                                json
                            }
                            internal {
                                type
                            }
                        }
                    }

                    heroImage {
                        fluid(maxWidth: 1172) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        description
                    }
                }
                ... on ContentfulRichText {
                    childContentfulRichTextTextRichTextNode {
                        json
                    }
                    internal {
                        type
                    }
                }
            }
        }
    }
`;
